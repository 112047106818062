<template>
  <app-overlay>
    <searchable-table
      :items="requestHistory"
      :fields="fields"
      hover
      @row-clicked="showDetails"
    >
      <template #modalbutton>
        <app-button
          class="flex-1-md"
          text="Leads (Olası Müşteri) Ekle"
          icon="PlusIcon"
          size="md"
        />
      </template>
      <template #status="{ item }">
        <b-badge :variant="$variants[item.status]">
          {{ $capitalize(item.status) }}
        </b-badge>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: 'leads', label: 'Leads Adı' },
        { key: 'kanal', label: 'Kanal' },
        { key: 'key2', label: 'İlk Temas Tarihi' },
        { key: 'lastCall', label: 'Son Görüşme Tarihi' },
        { key: 'status', label: 'Durum' },
        { key: 'actions', label: 'Eylemler' },
      ],
    }
  },
  computed: {
    requestHistory() {
      return this.$store.getters.requestHistory
    },
  },
  mounted() {
    this.$store.dispatch('getRequestHistory')
    this.$socket.on('WEB_CALL_REQUEST_UPDATED', data => {
      this.$store.commit('updateRequestHistory', data)
    })
  },
  destroyed() {
    this.$socket.off('WEB_CALL_REQUEST_UPDATED')
  },
  methods: {
    showDetails(item) {
      this.$showAppModal({
        component: () => import('./Leads/LeadsDetailTabs.vue'),
        size: 'custom',
        centered: false,
        item,
        title: `${item?.phone} - Arama Detayları`,
      })
    },
  },
}
</script>

<style></style>
